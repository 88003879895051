import { DEBUG } from '@glimmer/env';
const EMPTY_ARRAY = Object.freeze([]); // import Logger from './logger';
// let alreadyWarned = false;

function debugAssert(test, msg) {
  // if (!alreadyWarned) {
  //   alreadyWarned = true;
  //   Logger.warn("Don't leave debug assertions on in public builds");
  // }
  if (!test) {
    throw new Error(msg || 'assertion failure');
  }
}

function deprecate(desc) {
  console.warn(`DEPRECATION: ${desc}`);
}

let GUID = 0;

function initializeGuid(object) {
  return object._guid = ++GUID;
}

function ensureGuid(object) {
  return object._guid || initializeGuid(object);
}

function dict() {
  return Object.create(null);
}

function isDict(u) {
  return u !== null && u !== undefined;
}

function isObject(u) {
  return typeof u === 'object' && u !== null;
}

class DictSet {
  constructor() {
    this.dict = dict();
  }

  add(obj) {
    if (typeof obj === 'string') this.dict[obj] = obj;else this.dict[ensureGuid(obj)] = obj;
    return this;
  }

  delete(obj) {
    if (typeof obj === 'string') delete this.dict[obj];else if (obj._guid) delete this.dict[obj._guid];
  }

}

class StackImpl {
  constructor() {
    this.stack = [];
    this.current = null;
  }

  get size() {
    return this.stack.length;
  }

  push(item) {
    this.current = item;
    this.stack.push(item);
  }

  pop() {
    let item = this.stack.pop();
    let len = this.stack.length;
    this.current = len === 0 ? null : this.stack[len - 1];
    return item === undefined ? null : item;
  }

  nth(from) {
    let len = this.stack.length;
    return len < from ? null : this.stack[len - from];
  }

  isEmpty() {
    return this.stack.length === 0;
  }

  toArray() {
    return this.stack;
  }

}

function keys(obj) {
  return Object.keys(obj);
}

function unwrap(val) {
  if (val === null || val === undefined) throw new Error(`Expected value to be present`);
  return val;
}

function expect(val, message) {
  if (val === null || val === undefined) throw new Error(message);
  return val;
}

function unreachable(message = 'unreachable') {
  return new Error(message);
}

function exhausted(value) {
  throw new Error(`Exhausted ${value}`);
}

const tuple = (...args) => args;

const symbol = typeof Symbol !== 'undefined' ? Symbol : key => `__${key}${Math.floor(Math.random() * Date.now())}__`;
const DESTROY = symbol('DESTROY');

function isDestroyable(value) {
  return !!(value && value[DESTROY] !== undefined);
}

function isStringDestroyable(value) {
  return !!(value && typeof value === 'object' && typeof value.destroy === 'function');
}

function clearElement(parent) {
  let current = parent.firstChild;

  while (current) {
    let next = current.nextSibling;
    parent.removeChild(current);
    current = next;
  }
}

const SERIALIZATION_FIRST_NODE_STRING = '%+b:0%';

function isSerializationFirstNode(node) {
  return node.nodeValue === SERIALIZATION_FIRST_NODE_STRING;
}

const LINKED = new WeakMap();
const WILL_DROP = symbol('WILL_DROP');
const DID_DROP = symbol('DID_DROP');
const CHILDREN = symbol('CHILDREN');
const DESTRUCTORS = new WeakMap();

function isDrop(value) {
  if (value === null || typeof value !== 'object') return false;
  return value[DID_DROP] !== undefined;
}

function associate(parent, child) {
  associateDestructor(parent, destructor(child));
}

function associateDestructor(parent, child) {
  let associated = LINKED.get(parent);

  if (!associated) {
    associated = new Set();
    LINKED.set(parent, associated);
  }

  associated.add(child);
}

function peekAssociated(parent) {
  return LINKED.get(parent) || null;
}

function takeAssociated(parent) {
  let linked = LINKED.get(parent);

  if (linked && linked.size > 0) {
    LINKED.delete(parent);
    return linked;
  } else {
    return null;
  }
}

function willDestroyAssociated(parent) {
  let associated = LINKED.get(parent);

  if (associated) {
    associated.forEach(item => {
      item[WILL_DROP]();
    });
  }
}

function didDestroyAssociated(parent) {
  let associated = LINKED.get(parent);

  if (associated) {
    associated.forEach(item => {
      item[DID_DROP]();
      associated.delete(item);
    });
  }
}

function destructor(value) {
  let d = DESTRUCTORS.get(value);

  if (!d) {
    if (isDestroyable(value)) {
      d = new DestroyableDestructor(value);
    } else if (isStringDestroyable(value)) {
      d = new StringDestroyableDestructor(value);
    } else {
      d = new SimpleDestructor(value);
    }

    DESTRUCTORS.set(value, d);
  }

  return d;
}

function snapshot(values) {
  return new SnapshotDestructor(values);
}

class SnapshotDestructor {
  constructor(destructors) {
    this.destructors = destructors;
  }

  [WILL_DROP]() {
    this.destructors.forEach(item => item[WILL_DROP]());
  }

  [DID_DROP]() {
    this.destructors.forEach(item => item[DID_DROP]());
  }

  get [CHILDREN]() {
    return this.destructors;
  }

  toString() {
    return 'SnapshotDestructor';
  }

}

class DestroyableDestructor {
  constructor(inner) {
    this.inner = inner;
  }

  [WILL_DROP]() {
    willDestroyAssociated(this.inner);
  }

  [DID_DROP]() {
    this.inner[DESTROY]();
    didDestroyAssociated(this.inner);
  }

  get [CHILDREN]() {
    return LINKED.get(this.inner) || [];
  }

  toString() {
    return 'DestroyableDestructor';
  }

}

class StringDestroyableDestructor {
  constructor(inner) {
    this.inner = inner;
  }

  [WILL_DROP]() {
    if (typeof this.inner.willDestroy === 'function') {
      this.inner.willDestroy();
    }

    willDestroyAssociated(this.inner);
  }

  [DID_DROP]() {
    this.inner.destroy();
    didDestroyAssociated(this.inner);
  }

  get [CHILDREN]() {
    return LINKED.get(this.inner) || [];
  }

  toString() {
    return 'StringDestroyableDestructor';
  }

}

class SimpleDestructor {
  constructor(inner) {
    this.inner = inner;
  }

  [WILL_DROP]() {
    willDestroyAssociated(this.inner);
  }

  [DID_DROP]() {
    didDestroyAssociated(this.inner);
  }

  get [CHILDREN]() {
    return LINKED.get(this.inner) || [];
  }

  toString() {
    return 'SimpleDestructor';
  }

}

class ListContentsDestructor {
  constructor(inner) {
    this.inner = inner;
  }

  [WILL_DROP]() {
    this.inner.forEachNode(d => destructor(d)[WILL_DROP]());
  }

  [DID_DROP]() {
    this.inner.forEachNode(d => destructor(d)[DID_DROP]());
  }

  get [CHILDREN]() {
    let out = [];
    this.inner.forEachNode(d => out.push(...destructor(d)[CHILDREN]));
    return out;
  }

  toString() {
    return 'ListContentsDestructor';
  }

}

function debugDropTree(inner) {
  let hasDrop = isDrop(inner);
  let rawChildren = LINKED.get(inner) || null;
  let children = null;

  if (rawChildren) {
    children = [];

    for (let child of rawChildren) {
      children.push(debugDropTree(child));
    }
  }

  let obj = Object.create(null);
  obj.inner = inner;

  if (children) {
    obj.children = children;
  }

  obj.hasDrop = hasDrop;
  return obj;
}

function printDropTree(inner) {
  printDrop(destructor(inner));
}

function printDrop(inner) {
  console.group(String(inner));
  console.log(inner);
  let children = inner[CHILDREN] || null;

  if (children) {
    for (let child of children) {
      printDrop(child);
    }
  }

  console.groupEnd();
}

class ListNode {
  constructor(value) {
    this.next = null;
    this.prev = null;
    this.value = value;
  }

}

class LinkedList {
  constructor() {
    this.clear();
  }

  head() {
    return this._head;
  }

  tail() {
    return this._tail;
  }

  clear() {
    this._head = this._tail = null;
  }

  toArray() {
    let out = [];
    this.forEachNode(n => out.push(n));
    return out;
  }

  nextNode(node) {
    return node.next;
  }

  forEachNode(callback) {
    let node = this._head;

    while (node !== null) {
      callback(node);
      node = node.next;
    }
  }

  insertBefore(node, reference = null) {
    if (reference === null) return this.append(node);
    if (reference.prev) reference.prev.next = node;else this._head = node;
    node.prev = reference.prev;
    node.next = reference;
    reference.prev = node;
    return node;
  }

  append(node) {
    let tail = this._tail;

    if (tail) {
      tail.next = node;
      node.prev = tail;
      node.next = null;
    } else {
      this._head = node;
    }

    return this._tail = node;
  }

  remove(node) {
    if (node.prev) node.prev.next = node.next;else this._head = node.next;
    if (node.next) node.next.prev = node.prev;else this._tail = node.prev;
    return node;
  }

  [WILL_DROP]() {
    this.forEachNode(d => destructor(d)[WILL_DROP]());
  }

  [DID_DROP]() {
    this.forEachNode(d => destructor(d)[DID_DROP]());
  }

  get [CHILDREN]() {
    let out = [];
    this.forEachNode(d => out.push(...destructor(d)[CHILDREN]));
    return out;
  }

}

class ListSlice {
  constructor(head, tail) {
    this._head = head;
    this._tail = tail;
  }

  forEachNode(callback) {
    let node = this._head;

    while (node !== null) {
      callback(node);
      node = this.nextNode(node);
    }
  }

  head() {
    return this._head;
  }

  tail() {
    return this._tail;
  }

  toArray() {
    let out = [];
    this.forEachNode(n => out.push(n));
    return out;
  }

  nextNode(node) {
    if (node === this._tail) return null;
    return node.next;
  }

}

const EMPTY_SLICE = new ListSlice(null, null);
const {
  keys: objKeys
} = Object;

function assign(obj) {
  for (let i = 1; i < arguments.length; i++) {
    let assignment = arguments[i];
    if (assignment === null || typeof assignment !== 'object') continue;
    let keys = objKeys(assignment);

    for (let j = 0; j < keys.length; j++) {
      let key = keys[j];
      obj[key] = assignment[key];
    }
  }

  return obj;
}

function fillNulls(count) {
  let arr = new Array(count);

  for (let i = 0; i < count; i++) {
    arr[i] = null;
  }

  return arr;
}

function values(obj) {
  const vals = [];

  for (const key in obj) {
    vals.push(obj[key]);
  }

  return vals;
}

function strip(strings, ...args) {
  let out = '';

  for (let i = 0; i < strings.length; i++) {
    let string = strings[i];
    let dynamic = args[i] !== undefined ? String(args[i]) : '';
    out += `${string}${dynamic}`;
  }

  let lines = out.split('\n');

  while (lines.length && lines[0].match(/^\s*$/)) {
    lines.shift();
  }

  while (lines.length && lines[lines.length - 1].match(/^\s*$/)) {
    lines.pop();
  }

  let min = Infinity;

  for (let line of lines) {
    let leading = line.match(/^\s*/)[0].length;
    min = Math.min(min, leading);
  }

  let stripped = [];

  for (let line of lines) {
    stripped.push(line.slice(min));
  }

  return stripped.join('\n');
}
/**
 * Encodes a value that can be stored directly instead of being a handle.
 *
 * Immediates use the positive half of 32bits
 *
 * @param value - the value to be encoded.
 */


function encodeImmediate(value) {
  if (typeof value === 'number') {
    // 1073741827 - (-1) == 1073741828
    // 1073741827 - (-1073741820) == 2147483647
    // positive it stays as is
    // 0 - 1073741823
    return value < 0 ? 1073741827
    /* NEGATIVE_BASE */
    - value : value;
  }

  if (value === false) {
    return 1073741824
    /* FALSE */
    ;
  }

  if (value === true) {
    return 1073741825
    /* TRUE */
    ;
  }

  if (value === null) {
    return 1073741826
    /* NULL */
    ;
  }

  if (value === undefined) {
    return 1073741827
    /* UNDEFINED */
    ;
  }

  return exhausted(value);
}
/**
 * Decodes an immediate into its value.
 *
 * @param value - the encoded immediate value
 */


function decodeImmediate(value) {
  if (value > 1073741823
  /* MAX_INT */
  ) {
      switch (value) {
        case 1073741824
        /* FALSE */
        :
          return false;

        case 1073741825
        /* TRUE */
        :
          return true;

        case 1073741826
        /* NULL */
        :
          return null;

        case 1073741827
        /* UNDEFINED */
        :
          return undefined;

        default:
          // map 1073741828 to 2147483647 to -1 to -1073741820
          // 1073741827 - 1073741828 == -1
          // 1073741827 - 2147483647 == -1073741820
          return 1073741827
          /* NEGATIVE_BASE */
          - value;
      }
    }

  return value;
}
/**
 * True if the number can be stored directly or false if it needs a handle.
 *
 * This is used on any number type to see if it can be directly encoded.
 */


function isSmallInt(num) {
  return isInt(num, -1073741820
  /* MIN_INT */
  , 1073741823
  /* MAX_INT */
  );
}
/**
 * True if the encoded int32 operand or encoded stack int32 is a handle.
 */


function isHandle(encoded) {
  return encoded < 0;
}
/**
 * Encodes an index to an operand or stack handle.
 */


function encodeHandle(index, maxIndex = 2147483647
/* MAX_INDEX */
, maxHandle = -1
/* MAX_HANDLE */
) {
  if (index > maxIndex) {
    throw new Error(`index ${index} overflowed range 0 to ${maxIndex}`);
  } // -1 - 0 == -1
  // -1 - 1073741823 == -1073741824
  // -1073741825 - 0 == -1073741825
  // -1073741825 - 1073741823 == -2147483648


  return maxHandle - index;
}
/**
 * Decodes the index from the specified operand or stack handle.
 */


function decodeHandle(handle, maxHandle = -1
/* MAX_HANDLE */
) {
  // -1 - -1073741824 == 1073741823
  // -1073741825 - -1073741825 == 0
  // -1073741825 - -2147483648 == 1073741823
  return maxHandle - handle;
}

function isInt(num, min, max) {
  // this is the same as Math.floor(num) === num
  // also NaN % 1 is NaN and Infinity % 1 is NaN so both should fail
  return num % 1 === 0 && num >= min && num <= max;
}

function unwrapHandle(handle) {
  if (typeof handle === 'number') {
    return handle;
  } else {
    let error = handle.errors[0];
    throw new Error(`Compile Error: ${error.problem} @ ${error.span.start}..${error.span.end}`);
  }
}

function unwrapTemplate(template) {
  if (template.result === 'error') {
    throw new Error(`Compile Error: ${template.problem} @ ${template.span.start}..${template.span.end}`);
  }

  return template;
}

function extractHandle(handle) {
  if (typeof handle === 'number') {
    return handle;
  } else {
    return handle.handle;
  }
}

function isOkHandle(handle) {
  return typeof handle === 'number';
}

function isErrHandle(handle) {
  return typeof handle === 'number';
}

let debugToString;

if (DEBUG) {
  let getFunctionName = fn => {
    let functionName = fn.name;

    if (functionName === undefined) {
      let match = Function.prototype.toString.call(fn).match(/function (\w+)\s*\(/);
      functionName = match && match[1] || '';
    }

    return functionName.replace(/^bound /, '');
  };

  let getObjectName = obj => {
    let name;
    let className;

    if (obj.constructor && obj.constructor !== Object) {
      className = getFunctionName(obj.constructor);
    }

    if ('toString' in obj && obj.toString !== Object.prototype.toString && obj.toString !== Function.prototype.toString) {
      name = obj.toString();
    } // If the class has a decent looking name, and the `toString` is one of the
    // default Ember toStrings, replace the constructor portion of the toString
    // with the class name. We check the length of the class name to prevent doing
    // this when the value is minified.


    if (name && name.match(/<.*:ember\d+>/) && className && className[0] !== '_' && className.length > 2 && className !== 'Class') {
      return name.replace(/<.*:/, `<${className}:`);
    }

    return name || className;
  };

  let getPrimitiveName = value => {
    return String(value);
  };

  debugToString = value => {
    if (typeof value === 'function') {
      return getFunctionName(value) || `(unknown function)`;
    } else if (typeof value === 'object' && value !== null) {
      return getObjectName(value) || `(unknown object)`;
    } else {
      return getPrimitiveName(value);
    }
  };
}

var debugToString$1 = debugToString;

function assertNever(value, desc = 'unexpected unreachable branch') {
  console.log('unreachable', value);
  console.trace(`${desc} :: ${JSON.stringify(value)} (${value})`);
}

export { assertNever, EMPTY_ARRAY, debugAssert as assert, deprecate, dict, DictSet, isDict, isObject, StackImpl as Stack, ensureGuid, initializeGuid, isSerializationFirstNode, SERIALIZATION_FIRST_NODE_STRING, EMPTY_SLICE, LinkedList, ListNode, ListSlice, assign, fillNulls, values, debugToString$1 as debugToString, DESTROY, isDestroyable, isStringDestroyable, clearElement, LINKED, WILL_DROP, DID_DROP, CHILDREN, DESTRUCTORS, isDrop, associate, associateDestructor, peekAssociated, takeAssociated, willDestroyAssociated, didDestroyAssociated, destructor, snapshot, ListContentsDestructor, debugDropTree, printDropTree, printDrop, keys, unwrap, expect, unreachable, exhausted, tuple, symbol, strip, encodeImmediate, decodeImmediate, isSmallInt, isHandle, encodeHandle, decodeHandle, unwrapHandle, unwrapTemplate, extractHandle, isOkHandle, isErrHandle };